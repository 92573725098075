import React from 'react'
import classnames from 'classnames'
import { ReactComponent as Phone } from '../icons/phone.svg'
import { ReactComponent as Mail } from '../icons/mail.svg'
import ListItem from './ListItem'

export default function ContactSection({ className }) {
  return (
    <div className={className}>
      <h3 className={classnames('font-primary')}>Kontakt</h3>
      <ul>
        <ListItem icon={Phone}>062 929 05 05</ListItem>
        <ListItem icon={Mail}>info@stucki-roggwil.ch</ListItem>
      </ul>
    </div>
  )
}
