import React from 'react'
import classnames from 'classnames'
import { Link as RouterLink } from 'wouter'
import styles from './Link.module.css'

export default function Link({
  children,
  secondary = false,
  className = undefined,
  ...rest
}) {
  return (
    <RouterLink
      className={classnames(
        className,
        secondary ? 'text-primary' : 'text-white',
        secondary ? 'bg-white' : 'bg-primary',
        'inline-block',
        'px-6',
        'py-4',
        'uppercase',
        {
          [styles.outline]: secondary,
        }
      )}
      {...rest}
    >
      {children}
    </RouterLink>
  )
}
