import React from 'react'
import { Switch, Route } from 'wouter'
import './assets/main.css'
import Home from './sites/home/Home'
import Buy from './sites/buy/Buy'
import BuyDetail from './sites/buydetail/Buydetail'
import Rent from './sites/rent/Rent'
import RentDetail from './sites/rentdetail/Rentdetail'

function App() {
  return (
    <Switch>
      <Route path="/kaufen/:id" component={BuyDetail} />
      <Route path="/kaufen" component={Buy} />
      <Route path="/mieten" component={Rent} />
      <Route path="/mieten/:id" component={RentDetail} />
      <Route path="" component={Home} />
    </Switch>
  )
}

export default App
