import React from 'react'
import classnames from 'classnames'
import Header from './Header'

export default function BlueHeader() {
  return (
    <div className={classnames('bg-primary')}>
      <div className={classnames('container', 'mx-auto', 'px-4')}>
        <Header />
      </div>
    </div>
  )
}
