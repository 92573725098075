import React from 'react'
import classnames from 'classnames'
import { Link } from 'wouter'

export default function Header({ secondary = false }) {
  return (
    <header
      className={classnames('flex', 'justify-between', 'py-3', {
        ['text-white']: !secondary,
      })}
    >
      <Link href="/">
        <h1 className={classnames('font-primary', 'text-xl')}>
          Freizeitmobile Stucki
        </h1>
        <span>Ihr Partner für Wohnmobile</span>
      </Link>
      {secondary ? null : (
        <div className={classnames('flex', 'items-center')}>
          <Link href="/kaufen">Kaufen</Link>
          <Link href="/mieten" className="m-2">
            Mieten
          </Link>
        </div>
      )}
    </header>
  )
}
