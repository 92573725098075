import React from 'react'
import classnames from 'classnames'
import Link from '../../components/Link'
// import styles from './Buy.module.css'
import { ReactComponent as Sleeps } from '../../icons/sleeps.svg'
import { ReactComponent as Camion } from '../../icons/camion.svg'
import { ReactComponent as Dimensions } from '../../icons/dimensions.svg'
import { ReactComponent as Weight } from '../../icons/weight.svg'
import BlueHeader from '../../components/BlueHeader'
import baseUrl from '../../common/baseUrl'
import ListItem from '../../components/ListItem'
import Gallery from '../../components/Gallery'
import ContactSection from '../../components/ContactSection'

export default function BuyDetail({ params }) {
  const [vehicle, setVehicle] = React.useState(null)
  React.useEffect(() => {
    fetch(`${baseUrl}/sell/${params.id}.json`)
      .then((response) => response.json())
      .then((json) => {
        setVehicle(json)
      })
  }, [])

  if (vehicle === null) {
    return null
  }
  return (
    <>
      <BlueHeader />
      <div className={classnames('container', 'mx-auto', 'px-4')}>
        <div
          className={classnames('flex', 'justify-between', 'pt-4', 'flex-wrap')}
        >
          <h2 className={classnames('font-primary', 'text-lg')}>
            {vehicle.title}
          </h2>
          <span className={classnames('font-primary')}>
            {new Intl.NumberFormat('de-CH').format(vehicle.price)} CHF
          </span>
        </div>
        <Gallery images={vehicle.images} />
        <div className={classnames('flex', 'flex-wrap', 'pt-4')}>
          <div className={classnames('pr-4')}>
            <h3 className={classnames('font-primary')}>Technische Daten</h3>
            <ul>
              <ListItem icon={Sleeps}>
                {vehicle.sleepSeats} Schlafplätze
              </ListItem>
              <ListItem icon={Dimensions}>
                {vehicle.length}m x {vehicle.height}m x {vehicle.width}m
              </ListItem>
              <ListItem icon={Camion}>{vehicle.horsePower} PS</ListItem>
              <ListItem icon={Weight}>
                {vehicle.fullWeight} kg Maxgewicht
              </ListItem>
            </ul>
          </div>
          <ContactSection />
        </div>
      </div>
    </>
  )
}
