import React from 'react'
import classnames from 'classnames'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isSameDay from 'date-fns/isSameDay'
import Link from '../../components/Link'
// import styles from './Buy.module.css'
import { ReactComponent as Sleeps } from '../../icons/sleeps.svg'
import { ReactComponent as Camion } from '../../icons/camion.svg'
import { ReactComponent as Dimensions } from '../../icons/dimensions.svg'
import { ReactComponent as Weight } from '../../icons/weight.svg'
import { ReactComponent as HighSeason } from '../../icons/highseason.svg'
import { ReactComponent as OffSeason } from '../../icons/offseason.svg'
import BlueHeader from '../../components/BlueHeader'
import baseUrl from '../../common/baseUrl'
import ListItem from '../../components/ListItem'
import Gallery from '../../components/Gallery'
import ContactSection from '../../components/ContactSection'
import './calendar-overwrite.css'

export default function RentDetail({ params }) {
  const [vehicle, setVehicle] = React.useState(null)
  React.useEffect(() => {
    fetch(`${baseUrl}/rent/${params.id}.json`)
      .then((response) => response.json())
      .then((json) => {
        setVehicle(json)
      })
  }, [])

  if (vehicle === null) {
    return null
  }
  return (
    <>
      <BlueHeader />
      <div className={classnames('container', 'mx-auto', 'px-4')}>
        <div className={classnames('pt-4')}>
          <h2 className={classnames('font-primary', 'text-lg')}>
            {vehicle.title}
          </h2>
        </div>
        <Gallery images={vehicle.images} />
        <div className={classnames('flex', 'flex-wrap', 'pt-4')}>
          <div className={classnames('pr-4')}>
            <h3 className={classnames('font-primary')}>Technische Daten</h3>
            <ul>
              <ListItem icon={Sleeps}>
                {vehicle.sleepSeats} Schlafplätze
              </ListItem>
              <ListItem icon={Dimensions}>
                {vehicle.length}m x {vehicle.height}m x {vehicle.width}m
              </ListItem>
              <ListItem icon={Camion}>{vehicle.horsePower} PS</ListItem>
              <ListItem icon={Weight}>
                {vehicle.fullWeight} kg Maxgewicht
              </ListItem>
            </ul>
          </div>
          <div className={classnames('pr-4')}>
            <h3 className={classnames('font-primary')}>Preise pro Woche</h3>
            <ul>
              <ListItem icon={HighSeason}>
                {vehicle.price} CHF Hauptsaison (Jul/Aug)
              </ListItem>
              <ListItem icon={OffSeason}>
                {vehicle.offseasonprice} CHF Nebensaison
              </ListItem>
            </ul>
          </div>
          <ContactSection className={classnames('pr-4')} />
          <div className={classnames('p-4')}>
            <Calendar
              tileClassName={({ date }) => {
                const booking = vehicle.dates.find(({ start, ende }) => {
                  const localStartDate = new Date(start.date)
                  const localEndDate = new Date(ende.date)
                  const startDate = new Date(
                    localStartDate.getFullYear(),
                    localStartDate.getMonth(),
                    localStartDate.getDate()
                  )
                  const endDate = new Date(
                    localEndDate.getFullYear(),
                    localEndDate.getMonth(),
                    localEndDate.getDate()
                  )
                  const referenceDate = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate()
                  )

                  return (
                    (isAfter(referenceDate, startDate) ||
                      isSameDay(referenceDate, startDate)) &&
                    (isBefore(referenceDate, endDate) ||
                      isSameDay(referenceDate, endDate))
                  )
                })
                return booking === undefined
                  ? ''
                  : 'react-calendar__month-view__days__day--booked'
              }}
              tileDisabled={({ view }) => view === 'month'}
              locale={'de'}
            />
          </div>
        </div>
      </div>
    </>
  )
}
