import React from 'react'
import classnames from 'classnames'

export default function ListItem({ icon: Icon, children }) {
  return children === null ? null : (
    <li>
      <span className={classnames('pr-3')}>
        <Icon className={classnames('inline')} />
      </span>
      {children}
    </li>
  )
}
