import React from 'react'
import classnames from 'classnames'
import Link from '../../components/Link'
import BlueHeader from '../../components/BlueHeader'
import baseUrl from '../../common/baseUrl'
import Downloads from '../../components/Downloads'

export default function Rent() {
  const [vehicles, setVehicles] = React.useState(null)
  React.useEffect(() => {
    fetch(`${baseUrl}/rent.json`)
      .then((response) => response.json())
      .then((json) => {
        setVehicles(json)
      })
  }, [])

  if (vehicles === null) {
    return null
  }
  return (
    <>
      <BlueHeader />
      <div
        className={classnames(
          'container',
          'mx-auto',
          'px-4',
          'flex',
          'flex-wrap'
        )}
      >
        {vehicles.data.map(({ id, title, image, chassisMake }) => (
          <div
            key={id}
            className="inline-block max-w-sm rounded overflow-hidden shadow-lg m-3"
          >
            <img className="w-full" src={`${baseUrl}/${image}`} />
            <div className="px-6 py-4">
              <div className="font-bold font-primary text-xl mb-2">{title}</div>

              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                {chassisMake}
              </span>

              <div className="pt-5">
                <a
                  href={`/mieten/${id}`}
                  className="inline-block bg-primary text-white w-full py-5 text-center uppercase font-primary"
                >
                  Details
                </a>
              </div>
            </div>
          </div>
        ))}
        <Downloads tag="rent" />
      </div>
    </>
  )
}
