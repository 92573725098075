import React from 'react'
import classnames from 'classnames'

import baseUrl from '../common/baseUrl'
import styles from './Gallery.module.css'

export default function Gallery({ images }) {
  return (
    <div className={classnames('flex', styles.gallery)}>
      {images.map((image) => {
        return <img key={image} src={`${baseUrl}/${image}`} />
      })}
    </div>
  )
}
