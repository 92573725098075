import React from 'react'
import classnames from 'classnames'
import { ReactComponent as Download } from '../icons/download.svg'
import baseUrl from '../common/baseUrl'
import ListItem from './ListItem'

export default function Downloads({ tag }) {
  const [downloads, setDownloads] = React.useState(null)
  React.useEffect(() => {
    fetch(`${baseUrl}/downloads.json`)
      .then((response) => response.json())
      .then((json) => {
        const files = json.data.filter((download) => {
          const foundTag = download.tags.find(
            ({ value, selected }) => selected && value === tag
          )
          return foundTag === undefined ? false : true
        })
        setDownloads({ data: files })
      })
  }, [])

  if (downloads === null || downloads.data.length === 0) {
    return null
  }
  return (
    <div className="inline-block max-w-sm rounded overflow-hidden shadow-lg m-3">
      <div className="px-6 py-4">
        <div className="font-bold font-primary text-xl mb-2">Downloads</div>

        <ul>
          {downloads.data.map(({ download, title }) => (
            <ListItem key={download} icon={Download}>
              <a href={`${baseUrl}${download}`}>{title}</a>
            </ListItem>
          ))}
        </ul>
      </div>
    </div>
  )
}
