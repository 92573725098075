import React from 'react'
import classnames from 'classnames'
import Link from '../../components/Link'
import styles from './Home.module.css'
import Header from '../../components/Header'

export default function Home() {
  return (
    <div
      className={classnames(
        'w-screen',
        'h-screen',
        'bg-cover',
        'bg-bottom',
        styles.background
      )}
    >
      <div className={classnames('h-full', 'w-full', styles.backdrop)}>
        <div
          className={classnames(
            'container',
            'mx-auto',
            'px-4',
            'text-white',
            'flex',
            'flex-col',
            'h-full',
            'w-full'
          )}
        >
          <Header secondary />
          <div
            className={classnames(
              'flex-grow',
              'flex',
              'flex-col',
              'justify-center'
            )}
          >
            <div className={classnames()}>
              <p className={classnames('font-primary', 'text-xl')}>
                Bei uns finden Sie alles
                <br /> rund um Wohnmobile.
              </p>
              <Link href="/kaufen">Kaufen</Link>
              <Link href="/mieten" secondary className="m-2">
                Mieten
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
